<template>

  <div class="address-card">
    <transition name="fade">
    <div v-if="showModal" class="full-screen-fader edit-address flex-column justify-content-center">
      <div class="utlz-container">
        <div class="utlz-row">
          <div class="utlz-col-lg-12">
            <span class="close-button" @click="closeModal()">
              <i class="fa fa-times-circle" aria-hidden="true"></i>
            </span>
            <div class="modal-content add-product-modal-content">
              <template v-if="loading">
                <p>Loading</p>
              </template>
              <template v-else>
                <div class="form-wrapper">
                  <h2>{{ modalTitle }}</h2>
                  <div class="form-group">
                    <label for="name">
                      {{ labelName }}
                    </label>
                    <input type="text" class="form-control" id="name" :placeholder="labelName" v-model="address.name">
                  </div>

                  <div class="form-group">
                    <label for="country">
                      {{ labelCountry }}
                    </label>
                    <select id="country" v-model="address.countryCode" class="form-control">
                      <option v-for="country in availableCountries" :key="country.Code" :value="country.Code">         
                        {{ country.Name }}
                      </option> 
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="address">
                      {{labelAddress }}
                    </label>
                    <input type="text" class="form-control" id="address" :placeholder="labelAddress" v-model="address.address">
                  </div>

                  <div class="address-form-group">
                    <div class="form-group">
                      <label for="city">
                        {{labelCity }}
                      </label>
                      <input type="text" class="form-control" id="city" :placeholder="labelCity" v-model="address.city">
                    </div>
                    <div class="form-group">
                      <label for="name" v-translation="{ code: 'label_quote_col_zip', type: 'label'}">
                        {{ labelZip }}
                      </label>
                      <input type="text" class="form-control" id="zipcode" :placeholder="labelZip" v-model="address.zipcode">
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="name" v-translation="{ code: 'label_quote_col_phone', type: 'label'}">
                       {{labelPhone }}
                    </label>                    
                    <input type="text" class="form-control" id="phone" :placeholder="labelPhone" v-model="address.phone">
                  </div>

                  <div class="form-group">
                    <label for="email">
                      {{labelEmail}}
                      </label>
                    <input type="text" class="form-control" id="email" :placeholder="labelEmail" v-model="address.email">
                  </div>
                
                  <a v-translation="{ code: 'button_save_address', type: 'button'}" @click="saveForm" class="btn-u btn-u-sea-shop btn-block"></a>
                </div>
              </template>

            </div>
          </div>  
        </div>
      </div>
    </div>
    </transition>

    <template v-if="showAddress">
      <div class="panel-white">
        <p>{{ address.name }}</p>
        <p>{{ address.address }}</p>
        <p>{{ address.zipCode }} {{ address.city }}</p>
        <p>{{ countryDisplayName }}</p>
        <p>{{ address.phone }}</p>
        <p>{{ address.email }}</p>
        <span class="edit-icon" @click="openModal">          
          <i class="fa fa-pencil-square-o"></i>
        </span>
      </div>
      <template v-if="addressIncomplete">
        <div class="alert alert-warning incomp-address" role="alert">Adres gegevens zijn incompleet</div>
      </template>
    </template>
    <template v-else>
      <div class="add-address panel-white" @click="openModal">
        <div class="user-icon">
          <i class="fa fa-user"></i>
          <i class="fa fa-plus"></i>
        </div>
      </div>
    </template>

  </div>
  
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
export default {
  props: {
    address: { type: Object, required: true },
    type: { type: String, required: true },
    modalTitle: { type: String, required: true },
  },
  data() {
    return {
      showModal: false,
      loading: false,
      availableCountries: [],
      endpoint: "/Bespoke/QuotationWebmethods.aspx/",
      labelName: window.vue.translations.label.label_quote_col_name,
      labelCountry: window.vue.translations.label.label_quote_col_cnt,
      labelAddress: window.vue.translations.label.label_quote_col_address,
      labelCity: window.vue.translations.label.label_quote_col_city,
      labelZip: window.vue.translations.label.label_quote_col_zip,
      labelPhone: window.vue.translations.label.label_quote_col_phone,
      labelEmail: window.vue.translations.label.label_quote_col_email,
      addressIncomplete: false
    }
  },
  computed: {
    ...mapGetters(['language']),
    showAddress() {
      return Object.keys(this.address).filter(x => this.address[x] !== '').length > 0;
    },
    countryDisplayName() {
      var index = this.availableCountries.findIndex(x => x.Code === this.address.countryCode);
      if (index > 0) {
        return this.availableCountries[index].Name;
      }
    }
  },
  methods: {
    openModal() {      
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;      
      this.loading = false;
    },
    saveForm() {
      this.$emit('updateAddressEvent', { address: this.address, type: this.type });
      this.showModal = false;
    }
  },
  created() {
    if (this.availableCountries.length === 0) {        
        this.loading = true;
        axios.post(this.endpoint + "GetAvailableCountries", { languageIso: this.language }).then(res => {
          var response = res.data.d; 
          if (response.IsSuccess) {
            this.availableCountries = response.Countries;
            this.loading = false;
          } else {
            alert('ERROR');
            this.loading = false;
            this.showModal = false;
          }
        })
      }      
  }
}
</script>

<style>

</style>