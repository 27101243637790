<template>
  <div class="row" v-if="!loading">
    <template v-if="!noResult">
      <template v-if="showPrintIframe">
        <iframe width="0" height="0" :src="iframeSource" id="print-frame" name="print-frame"></iframe>
      </template>

      <template v-if="showDeleteQuotation">
        <utlz-delete-quotation 
        :quoteId="quoteDetails.quoteNr"
        @deleteQuotationEvent="deleteQuotation($event)"
        @cancelPrompt="showDeleteQuotation = false"></utlz-delete-quotation>
      </template>

      <div v-if="showAddProductModal" class="full-screen-fader add-product flex-column justify-content-center utlz-reset">
        <div class="utlz-container">
          <div class="utlz-row">
            <div class="utlz-col-lg-12">
              <span class="close-button" @click="closeSearchProduct()">
                <i class="fa fa-times-circle" aria-hidden="true"></i>
              </span>
              <div class="modal-content add-product-modal-content utlz-row">
                <utlz-search-product
                  @addExistingProductToQuotation="addExistingProductToQuotation($event)"
                ></utlz-search-product>
              </div>
            </div>  
          </div>
        </div>
      </div>
      
      <div class="col-md-12">
        <h1 v-translation="{ code: 'title_quotation', type: 'title' }"></h1>
      </div>
      <div class="col-md-12">
        <div class="row">

          <div class="col-md-8">
            <div class="row">
              
              <div class="col-md-12">
                
                <div class="panel-grey-100 row quote-info">

                  <div class="col-md-6 meta-data">
                    <p>
                      <span class="label-bold" v-translation="{ code: 'label_quotes_nr', type: 'label' }"></span>
                      <span>{{ quoteDetails.quoteNr }}</span>
                    </p>
                    <div class="quote-date">
                      <span class="label-bold" v-translation="{ code: 'label_quotes_date', type: 'label' }"></span>
                      <div id="date-picker" class="flatpickr input-group" data-id="strap">
                        <input type="text" placeholder="Kies een datum" data-input class="form-control" />
                        <i class="fa fa-pencil"></i>
                      </div>
                    </div>

                    <div class="quote-date">
                      <span class="label-bold" v-translation="{ code: 'label_quotes_exp_date', type: 'label' }"></span>
                      <div id="exp-date-picker" class="flatpickr input-group" data-id="strap">
                        <input type="text" placeholder="Kies een datum" data-input class="form-control" />
                        <i class="fa fa-pencil"></i>
                      </div>
                    </div>

                  </div>                 

                  <div class="col-md-6 logo-col">
                    <utlz-upload-logo @saveCompanyLogo="SaveCompanyLogo($event)" :quoteId="quoteDetails.quoteNr" :imageUrl="quoteDetails.logoImgUrl"></utlz-upload-logo>
                  </div>
                  
                </div>
              </div>

              <div class="col-md-12 addresses">
                <div class="panel-grey-100 row">

                  <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <p class="label-bold" v-translation="{ code: 'label_quote_from', type: 'label' }"></p>
                        </div>
                        <div class="col-md-12">
                          <utlz-address-card :address="{ ...deliveryAddress }" :type="'delivery'" :modalTitle="'Offerte van'" @updateAddressEvent="updateAddress($event)"></utlz-address-card>                          
                        </div>
                      </div>
                  </div>

                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">
                        <p class="label-bold" v-translation="{ code: 'label_quote_to', type: 'label' }"></p>
                      </div>
                      <div class="col-md-12">
                        <utlz-address-card :address="{ ...invoiceAddress }" :type="'invoice'" :modalTitle="'Klant gegevens'" @updateAddressEvent="updateAddress($event)"></utlz-address-card>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
          <div class="col-md-4 utlz-reset">
            <ul class="action-links">
              <li class="text-link" @click="printQuote()">
                <i class="fa fa-print"></i>
                <span v-translation="{ code: 'button_print_quote', type: 'button'}">                  
                </span>
              </li>
              <li class="text-link" @click="initDeleteQuotation()">
                <i class="fa fa-trash"></i>
                <span v-translation="{ code: 'button_delete_quote', type: 'button'}"></span>
              </li>
            </ul>
          </div>

        </div>
      </div>
      <div class="col-md-12 quotation-lines">
        
        <div class="row lines-header panel-grey-100">
          <div class="col-md-1 prod-img label-bold"></div>
          <div class="col-md-6 prod-desc label-bold" v-translation="{ code: 'label_col_desc', type: 'label'}"></div>
          <div class="col-md-2 prod-price label-bold" v-translation="{ code: 'label_col_price', type: 'label'}"> </div>
          <div class="col-md-1 prod-qty label-bold" v-translation="{ code: 'label_col_qty', type: 'label'}"></div>
          <div class="col-md-1 row-amt label-bold" v-translation="{ code: 'label_col_amt', type: 'label'}"></div>
          <div class="col-md-1 delete label-bold"></div>
        </div>

        <div class="row lines-body panel-grey-100">
          <utlz-quotation-line
          @updateProductDescriptionEvent="updateDescription($event)"
          @deleteQuotationEvent="deleteQuotationLine($event)"
          @updateQuantityEvent="updateQuantity($event)" 
          @updatePriceEvent="updatePrice($event)"
          v-for="line in quoteLines" 
          :key="line.recId" 
          :quotation="line"></utlz-quotation-line>
          
          <div class="col-md-12 quotation-line add-line" @click="initAddProductModal()">
          <div class="row">

            <div class="col-md-1 prod-img">
              <div class="plus-icon">
                <i class="fa fa-plus"></i>
              </div>
            </div>
            <div class="col-md-4">            
              <p class="label-bold" v-translation="{ code: 'label_add_quote_line', type: 'label'}"></p>
              <p class="text-small text-grey-100" v-translation="{ code: 'label_add_quote_example', type: 'label'}">Voorbeeld montagekosten</p>
            </div>
          </div>
        </div>
        </div>
      </div>

      <div class="col-md-12 discounts">
        
        <div class="row panel-grey-100">
          <utlz-quotation-discount
            :quotation="quoteDetails"
            @updateDiscountEvent="updateDiscount"
          ></utlz-quotation-discount>
          <utlz-select-vat-percentage
            :quotation="quoteDetails"
            @updateVatEvent="updateVatPercentage($event)"
          ></utlz-select-vat-percentage>
        </div>
      </div>

      <div class="col-md-6 col-md-offset-6">
        <div class="row totals">
          <div class="col-12">
            <span v-translation="{ code: 'label_subtotal_excl', type: 'label' }"></span>
            <span class="label-bold">{{ totalExcl }}</span>
          </div>
          <div class="col-12">
            <span v-translation="{ code: 'label_disc_excl', type: 'label' }"></span>
            <span class="label-bold">{{ discountTotal }}</span>
          </div>
          <div class="col-12">
            <span v-translation="{ code: 'label_total_vat', type: 'label' }"></span>
            <span class="label-bold">{{ vatTotal }}</span>
          </div>
          <div class="col-12">
            <span v-translation="{ code: 'label_total_incl_vat', type: 'label' }"></span>
            <span class="label-bold">{{ totalIncl }}</span>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="buttons">     
          
          <a :href="'/' + language + '/bespoke/account/quotations.aspx'" class="btn-u btn-u-sea-shop" v-translation="{ code: 'button_back_to_quotes', type: 'button'}">
          </a>
          
          <a class="btn-u btn-u-sea-shop" @click="printQuote()">
            <i class="fa fa-print"></i>
            <span v-translation="{ code: 'button_print_quote', type: 'button'}"></span>
          </a>

        </div>
      </div>

    </template>
    <template v-else>
      <p>No results found</p>
    </template>
  </div>

  <div v-else>
    <div class="col-md-12">
        <div id="spinner-screen">
          <div class="spinner-wrapper loading-quotes">
              <div class="loader"></div>              
              <span v-translation="{ code: 'label_loading_quote' , type: 'label'}"></span>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import SearchProduct from './../../bespoke/quotations/SearchProduct.vue';
import QuotationLine from './../../bespoke/quotations/QuotationLine.vue';
import AddressCard from './../../bespoke/quotations/AddressCard.vue';
import DeleteQuotation from './../../bespoke/quotations/DeleteQuotation.vue';
import PrintQuotation from './../../bespoke/quotations/PrintQuotation.vue';
import QuotationDiscounts from './../../bespoke/quotations/QuotationDiscounts.vue';
import SelectVatPercentage from './../../bespoke/quotations/SelectVatPercentage.vue';
import UploadLogo from './../../bespoke/quotations/UploadLogo.vue';
import { getProductInformation } from 'src/services/product-service';
import { formatPrice } from 'src/utilities/helpers';

export default {
  components: {
    'utlz-search-product': SearchProduct,
    'utlz-quotation-line': QuotationLine,
    'utlz-address-card': AddressCard,
    'utlz-delete-quotation': DeleteQuotation,
    'utlz-delete-quotation': DeleteQuotation,
    'utlz-quotation-discount': QuotationDiscounts,
    'utlz-select-vat-percentage': SelectVatPercentage,
    'utlz-print-quotation': PrintQuotation,
    'utlz-upload-logo': UploadLogo
  },
  data() {
    return {
      loading: true,
      showAddProductModal: false,
      endpoint: "/Bespoke/QuotationWebmethods.aspx/",
      quoteLines: [],
      quoteDetails: {},
      deliveryAddress: {},
      invoiceAddress: {},
      totalExcl: 0,
      discountTotal: 0,
      vatTotal: 0,
      totalIncl: 0,
      showDeleteQuotation: false,
      quoteListUrl: {},
      showPrintIframe: false,
      iframeSource: "",
      noResult: false,
      vatCodes: {},
      datePickersSet: false
    };
  },
  computed: {
    ...mapGetters(['customerUserId', 'bespokeGlobalWs', 'language']),
  },
  methods: {
    initAddProductModal() {
      this.showAddProductModal = true;
    },
    closeSearchProduct() {
      this.showAddProductModal = false;
    },
    initDeleteQuotation() {
      this.showDeleteQuotation = true;
    },
    updateDiscount({ amt, type }) {
      axios.post(this.endpoint + "UpdateQuotationDiscount", { recId: this.quoteDetails.quoteNr, amt: amt, type: type }).then(res => {
        var response = res.data.d; 
        if (response.IsSuccess) {       
          this.quoteDetails.discountType = type;
          this.quoteDetails.discountAmount = amt;
          this.setTotals(response.Totals);
        } else {
          alert('ERROR');
        }
      })
    },
    updateVatPercentage({ code }) {
      axios.post(this.endpoint + "UpdateVatPercentage", { quoteRecId: this.quoteDetails.quoteNr, vatCode: code }).then(res => {
        this.quoteDetails.vatCode = code;
        this.setTotals(res.data.d.Totals);
      })
    },
    deleteQuotation(quotationRecId) {
      axios.post(this.endpoint + "DeleteQuotations", { recId: quotationRecId }).then(res => {
        var response = res.data.d; 
        if (response.IsSuccess) {
          window.location.href = this.quoteListUrl;
        } else {
          alert('ERROR');
        }
      })
    },
    deleteQuotationLine(recId) {
      var quoteProductToDeleteIndex = this.quoteLines.findIndex(x => x.recId === recId);
      
      axios.post(this.endpoint + "DeleteQuotationLine", { recId: recId, hdrId: this.quoteDetails.quoteNr }).then(res => {
        var response = res.data.d; 
        if (response.IsSuccess) {          
          window.updateOrderMessage("Product is succesvol verwijderd");
          this.quoteLines.splice(quoteProductToDeleteIndex, 1);
          this.setTotals(response.Totals);
        } else {
          alert('ERROR');
        }
      })
    },
    updateDescription({ prodDesc, recId }) {
      var quoteProductToUpdate = this.quoteLines.find(x => x.recId === recId);      
      var payload = {
        recId: recId,
        hdrId: this.quoteDetails.quoteNr,
        prodCode: quoteProductToUpdate.productId, 
        prodDesc: prodDesc,
        quoteQty: quoteProductToUpdate.quantity,
        vatCode: this.quoteDetails.vatCode,
        prodPrice: quoteProductToUpdate.productPrice,
        rowAmt: quoteProductToUpdate.rowAmount,
        rowOrd: quoteProductToUpdate.rowOrder
      };

      axios.post(this.endpoint + "UpdateQuotationLine", payload).then(res => {
        var response = res.data.d; 
        if (response.IsSuccess) {          
          quoteProductToUpdate.productDescription = prodDesc;
        } else {
          alert('ERROR');
        }
      })
    },
    updatePrice({ price, rowAmount, recId }) {
      var quoteProductToUpdate = this.quoteLines.find(x => x.recId === recId);      
      var payload = {
        recId: recId,
        hdrId: this.quoteDetails.quoteNr,
        prodCode: quoteProductToUpdate.productId, 
        prodDesc: quoteProductToUpdate.productDescription,
        quoteQty: quoteProductToUpdate.quantity,
        vatCode: this.quoteDetails.vatCode,
        prodPrice: price,
        rowAmt: rowAmount,
        rowOrd: quoteProductToUpdate.rowOrder
      };

      axios.post(this.endpoint + "UpdateQuotationLine", payload).then(res => {
        var response = res.data.d; 
        if (response.IsSuccess) {          
          quoteProductToUpdate.productPrice = price;
          quoteProductToUpdate.rowAmount = rowAmount;
          this.setTotals(response.Totals);
        } else {
          alert('ERROR');
        }
      })
    },
    updateQuoteDate(selectedDate) {
      var payload = {
        quotationId: this.quoteDetails.quoteNr,
        quotationDate: selectedDate
      };

      axios.post(this.endpoint + "UpdateQuoteDate", payload).then(res => {
        var response = res.data.d; 
        if (response.IsSuccess) {          
          // Update date
          this.setTotals(response.Totals);
        } else {
          alert('ERROR');
        }
      });
    },
    updateQuoteExpirationDate(selectedDate) {
      var payload = {
        quotationId: this.quoteDetails.quoteNr,
        quotationDate: selectedDate
      };

      axios.post(this.endpoint + "UpdateQuoteExpirationDate", payload).then(res => {
        var response = res.data.d; 
        if (response.IsSuccess) {          
          // Update date
          this.setTotals(response.Totals);
        } else {
          alert('ERROR');
        }
      });
    },
    updateQuantity({ quantity, rowAmount, recId }) {
      var quoteProductToUpdate = this.quoteLines.find(x => x.recId === recId);
      
      var payload = {
        recId: recId,
        hdrId: this.quoteDetails.quoteNr,
        prodCode: quoteProductToUpdate.productId, 
        prodDesc: quoteProductToUpdate.productDescription,
        quoteQty: quantity,
        vatCode: this.quoteDetails.vatCode,
        prodPrice: quoteProductToUpdate.productPrice,
        rowAmt: rowAmount,
        rowOrd: quoteProductToUpdate.rowOrder
      };

      axios.post(this.endpoint + "UpdateQuotationLine", payload).then(res => {
        var response = res.data.d; 
        if (response.IsSuccess) {          
          quoteProductToUpdate.quantity = quantity;
          quoteProductToUpdate.rowAmount = rowAmount;
          this.setTotals(response.Totals);
        } else {
          alert('ERROR');
        }
      })
    },
    addExistingProductToQuotation(quotationProduct) {

      var payload = {
        hdrId: this.quoteDetails.quoteNr,
        prodCode: quotationProduct.productId, 
        prodDesc: quotationProduct.productDescription,
        quoteQty: quotationProduct.quantity,
        vatCode: this.quoteDetails.vatCode,
        prodPrice: quotationProduct.productPrice,
        rowAmt: quotationProduct.productPrice * quotationProduct.quantity,
        rowOrd: this.quoteLines.length + 1
      };

      axios.post(this.endpoint + "CreateQuotationLine", payload).then(res => {
        var response = res.data.d; 
        if (response.IsSuccess) {       
          quotationProduct.rowOrder = payload.rowOrd;
          quotationProduct.recId = response.RecId;
          this.quoteLines.push(quotationProduct);
          this.showAddProductModal = false;
          this.setTotals(response.Totals);
        } else {
          alert('ERROR');
        }
      })
    },
    updateAddress({ type, address }) {
      var addressDto = {
        Name: address.name,
        Address: address.address,
        ZipCode: address.zipCode,
        City: address.city,
        Phone: address.phone,
        CntCode: address.countryCode,
        Email: address.email
      };

      var endpoint = type === 'invoice' ? this.endpoint + "UpdateInvoiceAddress" : this.endpoint + "UpdateDeliveryAddress"

      axios.post(endpoint, { quoteRecId: this.quoteDetails.quoteNr, newAddress: addressDto }).then(res => {
        var response = res.data.d; 
        if (response) {          
          window.updateOrderMessage("Adres is succesvol gewijzigd");
          if (type === 'invoice') {
            this.invoiceAddress = address;
          } else if (type === 'delivery') {
            this.deliveryAddress = address;
          }
        } else {
          alert('ERROR');
        }
      })
    },
    setTotals({ TotalExcl, VatTotal, GrandTotal, Disc}) {
      this.totalExcl = formatPrice(TotalExcl);
      this.discountTotal = formatPrice(Disc);
      this.vatTotal = formatPrice(VatTotal);
      this.totalIncl = formatPrice(GrandTotal);
      this.quoteDetails.totalsIncl = GrandTotal;
    },    
    printQuote() {
      this.showPrintIframe = false;
      this.$nextTick(() => {
        this.showPrintIframe = true;
      });
    },
    SaveCompanyLogo(logoImgUrl) {
      this.quoteDetails.logoImgUrl = logoImgUrl;
    },
    setDatePicker(elementId, defaultDate, callback) {
      var config = {
        locale: "nl",
        wrap: true,
        inline: false,
        altInput: true,
        dateFormat: "d-m-Y\\TH:i:S\\Z",
        altFormat: "d-m-Y",
        defaultDate: defaultDate,
        onChange: (selectedDates, dateStr, instance) => {
          callback(dateStr);
        },
        onOpen: function () {
            //dotNetReference.invokeMethodAsync('SetFocus', true);
        },
        onClose: function () {
            //dotNetReference.invokeMethodAsync('SetFocus', false);
        }
      };

      $('#' + elementId).flatpickr(config);
    }
  },
  updated() {
    if (!this.datePickersSet) {
      this.setDatePicker('date-picker', this.quoteDetails.quoteDate, this.updateQuoteDate);
      this.setDatePicker('exp-date-picker', this.quoteDetails.quoteExpirationDate, this.updateQuoteExpirationDate);
      this.datePickersSet = true;
    } 
  },
  created() {    

    this.quoteListUrl = new URL(location.origin + "/" + this.language + "/bespoke/account/quotations.aspx");

    var quoteId = new URLSearchParams(window.location.search).get('quoteid');

    if (quoteId !== null) {
      
      axios.post(this.endpoint + "GetQuotations", { debtorId: this.customerUserId, recId: quoteId })
        .then(res => {
          if (res.data.d.length > 0) {

          var details = res.data.d[0];
        
          this.setTotals(details);

          var dateString = details.QuoteDate;
          let parts = dateString.split('-');
          let formattedDateString = `${parts[1]}/${parts[0]}/${parts[2]}`;

          var expirationDateString = details.QuoteExpirationDate;
          let partsExp = expirationDateString.split('-');
          let formattedExpirationDateString = `${partsExp[1]}/${partsExp[0]}/${partsExp[2]}`;

          this.quoteDetails = {
            debtorId: "",
            discountAmount: details.Disc,
            discountType: details.DiscType,
            quoteDate: new Date(formattedDateString).format("dd-MM-yyyy"),
            quoteExpirationDate: new Date(formattedExpirationDateString).format("dd-MM-yyyy"),
            quoteNr: details.RecId,
            grandTotal: details.GrandTotal,
            totalExcl: details.TotalExcl,
            totalIncl: details.TotalIncl,
            vatTotal: details.VatTotal,
            vatCode: details.VatCode,
            logoImgUrl: details.LogoImgUrl
          };

          var lines = [];
          details.Lines.forEach(x => {
            lines.push({
              productId: x.ProdCode,
              productDescription: x.ProdDesc,
              quantity: x.Qty,
              rowAmount: x.LineTotal,
              productPrice: x.ProductPrice,
              recId: x.RecId,
              rowOrder: x.RowOrd,
              vatCode: x.VatCode,
              customProduct: x.ProdCode === this.bespokeGlobalWs.customQuoteProduct ? true : false
            });
          });

          this.deliveryAddress = {
            name: details.DelAddress.Name,
            address: details.DelAddress.Address,
            countryCode: details.DelAddress.CntCode,
            email: details.DelAddress.Email,
            city: details.DelAddress.City,
            phone: details.DelAddress.Phone,
            zipCode: details.DelAddress.ZipCode
          };

          this.invoiceAddress = {
            name: details.InvAddress.Name,
            address: details.InvAddress.Address,
            countryCode: details.InvAddress.CntCode,
            email: details.InvAddress.Email,
            city: details.InvAddress.City,
            phone: details.InvAddress.Phone,
            zipCode: details.InvAddress.ZipCode
          };

          var prodCodes = lines.filter(y => !y.customProduct).map(x => x.productId);
          if (prodCodes.length > 0) {
            getProductInformation({ prodCodes: prodCodes, getSeoInformation: false, getStock: false, getPrice: false })
            .then(res => {
              res.products.forEach(productInfo => {
                lines.forEach(x => {
                  if (x.productId === productInfo.id) {                      
                    x.imageUrl = productInfo.imageUrl ? productInfo.imageUrl : '/documents/productimages/not-available-small.jpg';
                  }
                });

              });

              this.quoteLines = lines;
              this.loading = false;
            });          
          } else {
            this.loading = false;
            this.quoteLines = lines;
          }

          var iframeSource = new URL(location.origin + "/" + this.language + "/bespoke/account/printquotation.aspx");
          iframeSource.searchParams.append('quoteid', this.quoteDetails.quoteNr);
          this.iframeSource = iframeSource.href; 
        } else {
          this.loading = false;
          this.noResult = true;
        }
      });   
    }
    
  }
}
</script>

<style>

</style>