<template>
<div class="select-quote">
  <div class="upload-logo-btn">
    
    <a @click="showUploadLogoEvent()">
      <template v-if="imageUrl">        
        <img :src="imageUrl" />
      </template>
      <template v-else>
        <span class="upload-logo-btn-ph">          
          <i class="fa fa-upload"></i>
          Upload logo
        </span>
      </template>
      
    </a>
  </div>
  
  <transition name="fade">
    <div v-if="showUploadLogo" class="full-screen-fader flex-column justify-content-center" :class="{'disabled': uploadingFile}">
      <div class="utlz-container">
        <div class="utlz-row">
          <div class="utlz-col-lg-12">
            <span class="close-button" @click="closeModal()">
              <i class="fa fa-times-circle" aria-hidden="true"></i>
            </span>
            <div class="modal-content upload-logo-modal row">              
              <div class="col-md-12">
                <h2>Upload een bestand</h2>
              </div>

              <div class="col-md-12">                
                <div class="upload-btn">
                  <div class="target">
                    <i class="fa fa-upload"></i>
                    <p>Klik hier om een bestand te uploaden</p>
                  </div>
                  <input type="file" @change="onFileChange" accept="image/png,image/jpeg,image/svg+xml,image/webp">
                </div>
              </div>
              
              <template v-if="existingImageUrl !== ''" >
                <div class="col-md-12 preview existing-img">
                  <div class="existing-file-row" >
                      <p v-translation="{ type: 'label', code: 'text_image_exist' }"></p>
                      <div class="uploaded-file">                        
                        <img :src="existingImageUrl" >
                        <a @click="SaveLogo()" class="btn-u btn-u-sea-shop save-logo-btn" v-translation="{ type: 'button', code: 'button_save_existing_logo'}"></a>
                      </div>
                  </div>
                </div>
              </template>

              <div class="col-md-12 errors">
                <template v-if="fileIsInvalid">
                  <div class="alert alert-danger alert-file-size" role="alert" v-html="errorMessage">  
                  </div>
                </template>
              </div>
              
              <template v-if="imageAsBytes !== ''" > 
                <div class="col-md-12 preview new-img">
                  <div class="uploaded-file-row">
                    <div class="uploaded-file">
                      
                      <!-- Only show delete button if file was not written away to the db yet -->
                      <template v-if="imageUrl">
                        <i @click="deleteFile" class="fa fa-times-circle delete-btn" aria-hidden="true"></i>                     
                      </template>

                      <img :src="imageAsBytes">
                    </div>    
                      <template v-if="!fileIsInvalid  && controlIsTainted">
                        <a @click="SaveLogo()" class="btn-u btn-u-sea-shop save-logo-btn" v-translation="{ type: 'button', code: 'button_save_logo'}"></a>
                      </template>
                  </div>
                </div>
              </template>
              
            </div>
          </div>  
        </div>
      </div>
    </div>
    </transition>

  </div>
</template>

<script>

import axios from 'axios';
export default {
  props: {
    quoteId: { type: String, required: false, default: ""},
    imageUrl: { type: String, required: false, default: ""}
  },
  data() {
    return {
      uploadingFile: false,
      showUploadLogo: false,
      existingImageUrl: "",
      existingImageUrl: "",
      controlIsTained: false,
      fileIsInvalid: false,
      errorMessage: "",
      maxFileSizeInMB: 1,
      controlIsTainted: false,
      acceptedFileTypes: ['image/png', 'image/jpeg', 'image/svg+xml', 'image/webp'],      
      endpoint: "/Bespoke/QuotationWebmethods.aspx/",
      uploadedFileAsFile: null,
      imageAsBytes: "",
      imageAsBytesDraft: "",
      uploadedFileName: "" 
    }
  },
  computed: {
  },
  methods: {
    showUploadLogoEvent() {
      this.showUploadLogo = true;
    },
    closeModal() {
      this.showUploadLogo = false;
      this.imageAsBytes = this.imageUrl;
      this.resetModalState();
    },
    onFileChange(e) {

      this.controlIsTainted = true;

      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;

      this.uploadedFileAsFile = files[0];

      var hasErrors = false;
      var errorMessages = "";

      if (this.uploadedFileAsFile.size / 1024 / 1024 > this.maxFileSizeInMB) {
        hasErrors = true;
        var message = window.vue.translations.label.label_filesize_exceeds;
        errorMessages += "<p class='alert-text'>" + message.replace("[1]", this.maxFileSizeInMB)  + "</p>";
      }

      if (!this.acceptedFileTypes.includes(this.uploadedFileAsFile.type)) {
        errorMessages += "<p class='alert-text'>" + window.vue.translations.label.label_file_types+ "</p>";
        hasErrors = true;
      }

      if (hasErrors) {
        this.fileIsInvalid = true;
        this.errorMessage = errorMessages;
        return;
      } else {        
        this.fileIsInvalid = false;
        this.errorMessage = "";       
        this.createImage(this.uploadedFileAsFile);
      }
    },
    createImage(file) {
      this.uploadedFileName = file.name;
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imageAsBytesDraft = e.target.result;    
        this.validateFile();
      };
      reader.readAsArrayBuffer(file);
    },
    validateFile() {
      this.uploadingFile = true;
      axios.post(this.endpoint + "ValidateImage", { fileName: this.uploadedFileName, imageAsBytes: Array.from(new Uint8Array(this.imageAsBytesDraft))}).then(res => {
        var response = res.data.d; 
        if (response.ImageExists) {                 
          this.existingImageUrl = response.ImageUrl;
          this.imageAsBytes = "";
        } else if (response.IsValid) {          
          let reader = new FileReader();
          reader.onload = (e) => {
            this.imageAsBytes = e.target.result;
          };
          reader.readAsDataURL(this.uploadedFileAsFile);
          this.existingImageUrl = "";
        }
        else {
          window.updateErrorMessage('An error occurred');
        }
        this.uploadingFile = false;
      });
    },
    SaveLogo() {
      var imageUrl = this.existingImageUrl !== '' ? this.existingImageUrl : "";

      axios.post(this.endpoint + "SaveCompanyLogo", { filename: this.uploadedFileName, quotationId: this.quoteId, url: imageUrl, imageAsBytes: Array.from(new Uint8Array(this.imageAsBytesDraft)) }).then(res => {
        var response = res.data.d;

        if (response.IsSuccess) {
          this.$emit('saveCompanyLogo', response.ImageUrl);
          this.showUploadLogo = false;          
          this.resetModalState();
        } else {
          var errorMsg = window.vue.translations.label.label_err_add_prod_quote;
          window.updateErrorMessage(errorMsg);
        } 
        
        this.showUploadLogo = false;
      });
    },
    deleteFile() {
      axios.post(this.endpoint + "DeleteCompanyLogo", { quotationId: this.quoteId }).then(res => {
        var response = res.data.d;

        if (response.IsSuccess) {
          this.$emit('saveCompanyLogo', "");
        } else {
          var errorMsg = window.vue.translations.label.label_err_add_prod_quote;
          window.updateErrorMessage(errorMsg);
        } 
        
        this.imageAsBytes = "";
        this.showUploadLogo = false;
        this.resetModalState();
      });
    },
    resetModalState() {
      this.fileIsInvalid = false;
      this.controlIsTainted = false;
      this.uploadedFileAsFile = "",
      this.imageAsBytesDraft= "";
      this.uploadedFileName = ""; 
      this.existingImageUrl = "";
    }
  },
  created() {
    if (this.imageUrl) {
      this.imageAsBytes = this.imageUrl;
    }
  }
}
</script>