<template>
  <div class="row" v-if="!loadingQuotations">

    <template v-if="showDeleteQuotation">
      <utlz-delete-quotation 
      @deleteQuotationEvent="deleteQuotation($event)"
      @cancelPrompt="showDeleteQuotation = false"></utlz-delete-quotation>
    </template>

    <div class="col-md-12 title-row">
      <h1 v-translation="{ code: 'title_quotations', type: 'title' }"></h1>
      <a @click="createQuote()" class="btn-u btn-u-sea-shop" v-translation="{ code: 'button_new_quote', type: 'button' }"></a>
    </div>
    
    <template v-if="!loadingQuotations && quotations.length > 0">      
      <div class="col-md-12">
        <table class="table">
          <thead class="table-header rgba-banner">
            <th class="is-active" v-translation="{ code: 'label_col_isactive', type: 'label'}">                    
            </th>
            <th v-translation="{ code: 'label_col_quote', type: 'label'}">
            </th>
            <th v-translation="{ code: 'label_col_date', type: 'label'}">
            </th>
            <th v-translation="{ code: 'label_col_exp_date', type: 'label'}">
            </th>
            <th v-translation="{ code: 'label_col_customer', type: 'label'}">
            </th>
            <th v-translation="{ code: 'label_col_subtotal_exclvat', type: 'label'}">
            </th>
            <th class="buttons"></th>
          </thead>
          <tbody>
            <utlz-quotation-list-item v-for="line in quotations" 
            :key="line.id" 
            :quotation="line" 
            @deleteQuotationEvent="initDeleteQuotation($event)"
            @printQuotationEvent="printquotation($event)"></utlz-quotation-list-item>
          </tbody>
        </table>
      </div>
      </template>
      <template v-else>
        <div class="col-md-12">
          <h3 v-translation="{ code: 'title_no_quotes_found', type: 'title' }"></h3>
        </div>
      </template>


      <template v-if="showPrintIframe">
        <iframe  width="0" height="0" :src="iframeSource" id="print-frame" name="print-frame"></iframe>
      </template>
  </div>

  <div v-else>
    <div class="col-md-12">
      <div id="spinner-screen">
          <div class="spinner-wrapper loading-quotes">
              <div class="loader"></div>              
              <span v-translation="{ code: 'label_loading_quotes' , type: 'label'}"></span>
          </div>
      </div>
    </div>
  </div>

</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import QuotationsListItem from './QuotationListItem.vue';
import DeleteQuotation from './../../bespoke/quotations/DeleteQuotation.vue';
import axios from 'axios';

export default {
  data() {
    return {
      endpoint: "/Bespoke/QuotationWebmethods.aspx/",
      showPrintIframe: false,
      showDeleteQuotation: false,
      iframeSource: "",
      quoteIdToDelete: "",
      vatCode: ''
    };
  },
  computed: {
    ...mapGetters(['customerUserId', 'language']),
    ...mapGetters('quotations', ['quotations', 'loadingQuotations'])
  },
  components: {
    'utlz-quotation-list-item': QuotationsListItem,
    'utlz-delete-quotation': DeleteQuotation,
  },
  methods: {
    ...mapActions('quotations', ['getQuotations']),
    initDeleteQuotation(recId) {
      this.quoteIdToDelete = recId;
      this.showDeleteQuotation = true;
    },
    // TODO
    // Maybe move this to quoations vuex module
    deleteQuotation() {
      axios.post(this.endpoint + "DeleteQuotations", { recId: this.quoteIdToDelete }).then(res => {
        var response = res.data.d; 
        if (response.IsSuccess) {
          var indexOfLineToDelete = this.quotations.findIndex(x => x.id === this.quoteIdToDelete);
          this.quotations.splice(indexOfLineToDelete, 1);
          window.updateOrderMessage("Offerte is succesvol verwijderd");
        } else {
          alert('ERROR');
        }
        this.showDeleteQuotation = false;
        this.quoteIdToDelete = "";
      })
    },
    printquotation(recId) {
      // Shows a hidden iframe on the page loading in the print friendly 
      // version, sets the focus on it, and triggers a print action
      this.showPrintIframe = false;
      var iframeSource = new URL(location.origin + "/" + this.language + "/bespoke/account/printquotation.aspx");
      iframeSource.searchParams.append('quoteid', recId);
      this.iframeSource = iframeSource;

      this.$nextTick(() => {
        this.showPrintIframe = true;
      });
    },
    createQuote() {
      axios.post( this.endpoint + 'CreateQuotation', { debtorId: this.customerUserId, vatCode: this.vatCode })
      .then(res => {
        var response = res.data.d;
        if (response.IsSuccess) {
          var detailsUrl = new URL(location.origin + "/" + this.language + "/bespoke/account/quotationdetails.aspx");
          detailsUrl.searchParams.append('quoteid', response.RecId);
          window.location.href = detailsUrl;
        } else {  
          alert('ERROR');
        }
      });
    },
    getVatCodes() {
      axios.post("/Bespoke/QuotationWebmethods.aspx/GetAvailableVatCodes", { })
        .then(res => {
          if (res.data.d.IsSuccess) {
            this.vatCode = res.data.d.Vat[0].Code;
          }
        });
    }
  },
  created() {    
    this.getVatCodes();
  }
}

</script>

<style>

</style>