<template>
  <div class="cmp-related-products-matrix utlz-col-sm-12">
    <div class="utlz-row utlz-no-gutters utlz-gutters-sm item-wrapper">     
      <div class="product-row" v-for="(relatedProduct, index) in relatedProducts" :key="'relatedProduct-' + index">
        <div class="main-product-wrapper">
          <div class="main-product-row" @click="toggleRow('product-row' + index)">
            <img class="row-image" :src="relatedProduct.rowImage" />
            <h2>{{ relatedProduct.rowTitle }}</h2>
            <span class="counter">
              {{ relatedProduct.products.length }}
              <span v-translation="{ type: 'label', code: 'label_options'}"></span>
              <i class="fa fa-caret-down" aria-hidden="true"></i>  
            </span>
          </div>
          <div class="related-products" :ref="'product-row' + index">
            <div v-for="product in relatedProduct.products" :key="product.id" class="related-product-row">
              <a :href="product.targetUrl" class="prod-desc">
                <img class="row-image" :src="product.imageUrl" />
                <div class="product-heading">
                  <span class="prod-code">{{ product.id }}</span>
                  <h3>{{ product.shortDescription }}</h3>
                </div>    
              </a>
              <div class="prod-price">
                <utlz-product-price
                  v-if="product.prices.length"
                  :prices="product.prices[0]"
                ></utlz-product-price>
              </div>
              
              <div class="order-product">
                <utlz-order-product
                  v-if="product.prices.length" :product="product">
                </utlz-order-product>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProductListItem from 'src/components/webshop/products/product-list-item/ProductListItem.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
export default {
  props: {
    products: { type: Array, required: true },
    customDecimals: { type: Object, required: true }
  },
  components: {
    'utlz-order-product': OrderProduct,
    'utlz-product-price': ProductPrice,
    'utlz-product-list-item': ProductListItem
  },
  data () {
    return {
      
    };
  },
  computed: {
    ...mapGetters(['language']),
    relatedProducts () {
      const relatedProducts = [];
      this.products.forEach((product, index) => {
        let groupDescription = product.customStrings['GroupDescription_' + this.language]; 
        let prodTitle = groupDescription ? groupDescription : product.shortDescription;

        const relatedProductIndex = relatedProducts.findIndex(relation => { 
          return relation.rowTitle === groupDescription;        
        });

        if (relatedProductIndex > -1) {
          relatedProducts[relatedProductIndex].products.push(product);
        } else {
            relatedProducts.push({
            rowTitle: prodTitle,
            rowImage: product.imageUrl,
            products: [product],
            priority: this.customDecimals['RELATION_' + product.id]
          });
        }
      });
      return relatedProducts.sort((a, b) => b.priority - a.priority);
    }
  },
  methods: {
    toggleRow ($event) {
      this.$refs[$event][0].classList.toggle('expanded')
    }
  }
};
</script>

<style>
</style>
