<template>
  <div class="cmp-product-group">
    <!-- BESPOKE -->
    <div v-if="properties.length" class="prod-group-label">  
      <i class="fa fa-info-circle" aria-hidden="true"></i>
      <span  v-translation="{ type: 'label', code: 'select_grp_value' }"></span>    
    </div>
    <!-- END BESPOKE -->
      <div class="property" v-for="property in properties" v-bind:key="property">
        <span class="select-label">{{ property }}</span>
          <select :name="property.replace(' ', '-')" :id="'select-' + property.replace(' ', '-')" v-model="mainModel[property]" @change="onChange($event)">
              <option v-for="variant in getPropertyValues(property)" :key="variant.key" :value="variant.key">
                  {{ variant.value }}
              </option>
          </select>
      </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  props: {
    variants: { type: Array, required: true }
  },
  data () {
    return {
      mainModel: {}
    }
  },
  watch: {
    productDetails (n, o) {
      this.mainModel = this.GetMainModel(n)
    },
    variants (n, o) {
      this.mainModel = this.GetMainModel(this.productDetails)
    }
  },
  computed: {
    ...mapState('productDetails', ['productDetails']),
    properties () {
      if (this.variants.length > 0) {
        return Object.keys(this.variants[0].properties);
      }
      return [];
    },
    mainProductProperties () {
      if (this.variants.length > 0) {
        let variant = this.variants.find((x) => x.id === this.productDetails.id);
        if (variant) { return variant.properties; }
      }
      return [];
    }
  },
  methods: {
    ...mapActions('productDetails', ['updateShownProduct']),
    onChange (event) {
      this.updateShownProduct(event.target.value);
    },
    GetMainModel (n) {
      var obj = {}

      if (n !== undefined) { Object.keys(this.mainProductProperties).forEach((x) => obj[x] = n.id); }

      return obj;
    },
    getPropertyValues (property) {
      let returnList = [];
      let products = this.variants;
      let ogProperties = this.mainProductProperties;
      let mainProductId = this.productDetails.id;

      let propertyIndex = this.properties.indexOf(property)
      if (propertyIndex > 0) {
        let filterValues = []
        for (let i = 0; i < propertyIndex; i++) {
          let propertyName = this.properties[i];

          filterValues.push({ key: propertyName, value: ogProperties[propertyName] });
        }

        filterValues.forEach(function (x) {
          products = products.filter(function (y) {
            return y.properties[x.key] === x.value;
          })
        });
      }

      products.forEach(function (x) {
        if (x.properties[property] !== ogProperties[property] || x.id == mainProductId) {
          returnList.push({ key: x.id, value: x.properties[property] })
        }
      });

      return returnList.filter((value, index, self) => self.findIndex((x) => x.value === value.value) === index)
        .sort(function (x, y) {
          const valA = x.value.toUpperCase();
          const valB = y.value.toUpperCase();

          let comparison = 0;
          if (valA > valB) {
            comparison = 1;
          } else if (valA < valB) {
            comparison = -1;
          }
          return comparison;
        });
    }
  }
}
</script>

<style>

</style>
