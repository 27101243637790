<template>
    <div class="col-md-offset-6 col-md-6 select-vat">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-8 label-col">
              <label v-translation="{ code: 'label_vat_perc', type: 'label' }"></label>
            </div>
            <div class="col-md-4">
              <select class="form-control" v-model="quotation.vatCode" @change="updateVatPercentage($event)">
                <option v-for="vatCode in vatCodes" :key="vatCode.Code" :value="vatCode.Code">{{ vatCode.Percentage }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    quotation: { type: Object, required: true}
  },
  data() {
    return {
      vatCodes: []
    };
  },
  components: {
  },
  methods: {
    updateVatPercentage(ev) {
      var payload = {
        code: ev.target.value
      }
      this.$emit('updateVatEvent', payload);
    },
    getVatCodes() {
      axios.post("/Bespoke/QuotationWebmethods.aspx/GetAvailableVatCodes", { })
        .then(res => {
          if (res.data.d.IsSuccess) {
            var vatCodes = [];
            res.data.d.Vat.forEach(x => {
              var index = vatCodes.findIndex(y => y.Percentage === x.Percentage);
              if (index == -1) {
                vatCodes.push(x);
              }
            });
            this.vatCodes = vatCodes;
          }
        });
    }
  },
  created() {
    this.getVatCodes();
  }
}
</script>

<style>

</style>