import axios from 'axios';
import { Product } from 'src/models/product.model';
import { getProductInformation } from 'src/services/product-service';
// BESPOKE THERM-46: Added getCurrencySign
import { getCurrencySign } from 'src/utilities/helpers';
// END BESPOKE THERM-46


const state = {
  loadingQuotations: true,
  quotationsBaseAddress: "/Bespoke/QuotationWebmethods.aspx/",
  quotations: [],
  selectedQuote: null,
  selectedQuoteId: null,
  currentQuoteVatCode: '',
  isShowSelectQuotesTool: false,
  queingQuoteProduct: false,
  quoteProductInQue: null,
  defaultVatCode: null
};

const getters = {
  defaultVatCode(state) {
    return state.defaultVatCode;
  },
  loadingQuotations(state) {
    return state.loadingQuotations;
  },
  quotations(state) {
    return state.quotations
  },
  quotationsBaseAddress(state) {
    return state.quotationsBaseAddress;
  },
  selectedQuoteId(state) {    
    return state.selectedQuoteId;
  },
  selectedQuote(state) {
    return state.selectedQuote;
  },
  isShowSelectQuotesTool(state) {
    return state.isShowSelectQuotesTool;
  },
  quoteProductInQue(state) {
    return state.quoteProductInQue;
  },
  queueingQuoteProduct(state) {
    return state.queingQuoteProduct;
  }
};

const actions = {
  init ({ commit, dispatch }) {
    // Get quotations
    dispatch('getQuotations');
  },
  getVatCodes({ state, getters }) {
    return new Promise((resolve, reject) => {       
      axios.post(getters.quotationsBaseAddress + "GetAvailableVatCodes", { })
        .then(res => {
          if (res.data.d.IsSuccess) {
            state.defaultVatCode = res.data.d.Vat[0].Code;
            resolve();
          } else {
            reject();
          }
        });
    });
  },
  createQuote({ state, rootGetters, getters, dispatch }) {
    dispatch('getVatCodes').then(_ => {
      if (getters.defaultVatCode) {
        axios.post( getters.quotationsBaseAddress + 'CreateQuotation', { debtorId: rootGetters.customerUserId, vatCode: getters.defaultVatCode })
          .then(res => {
            var response = res.data.d;
            if (response.IsSuccess) {

              var dateString = response.QuoteDate;
              let parts = dateString.split('-');
              let formattedDateString = `${parts[1]}/${parts[0]}/${parts[2]}`;

              var newQuote = {
                id: response.RecId,
                date: new Date(formattedDateString).format("dd-MM-yyyy"),
                isActive: false,
                name: '',
                subtotalExclVat: 0,
                vatCode: getters.defaultVatCode
              };
              state.quotations.unshift(newQuote);
            } else {  
              alert('ERROR');
            }
          }); 
      }      
    });
  },
  getQuotations({ getters, rootGetters, commit, dispatch }) {    
    commit('setLoadingQuotations', true);
    axios.post(getters.quotationsBaseAddress + "GetQuotations", { debtorId: rootGetters.customerUserId, recId: '' })
      .then(res => {
        var lines = res.data.d.map(x => {
          
          var dateString = x.QuoteDate;
          let parts = dateString.split('-');
          let formattedDateString = `${parts[1]}/${parts[0]}/${parts[2]}`;

          var expDateString = x.QuoteExpirationDate;
          let expParts = expDateString.split('-');
          let formattedExpDateString = `${expParts[1]}/${expParts[0]}/${expParts[2]}`;

          return {
            id: x.RecId,
            date: new Date(formattedDateString).format("dd-MM-yyyy"),
            expirationDate: new Date(formattedExpDateString).format("dd-MM-yyyy"),
            name: x.InvAddress.Name,
            subtotalExclVat: x.TotalExcl,
            isActive: false,
            vatCode: x.VatCode
          }
        });
        commit('setQuotations', lines);
        commit('setLoadingQuotations', false);
        
        // See if there is an active quotations id in local storage
        var quoteIdStoredInSession = window.localStorage.getItem('selectedQuoteId');    
        if (quoteIdStoredInSession !== null) {      
          return new Promise((resolve, reject) => {            
            dispatch('setSelectedQuote', { quoteId: quoteIdStoredInSession, isActive: true });
            resolve();
          });
        }

      });
  },
  setSelectedQuote({ commit, getters }, { quoteId, isActive }) {
    var selectedQuoteIndex = getters.quotations.findIndex(x => x.id === quoteId);
    if (selectedQuoteIndex > -1) {
      commit('setSelectedQuote', {quoteId, isActive});
    }
  },
  addProductToQuotation({getters, commit}, quotationProduct) {
    return new Promise((resolve, reject) => {      
      var payload = {
        hdrId: getters.selectedQuote.id,
        prodCode: quotationProduct.productId, 
        prodDesc: quotationProduct.productDescription,
        quoteQty: quotationProduct.quantity,
        vatCode: getters.selectedQuote.vatCode,
        prodPrice: quotationProduct.productPrice,
        rowAmt: quotationProduct.productPrice * quotationProduct.quantity,
        rowOrd: getters.quotations.length + 1
      };
      
      axios.post(getters.quotationsBaseAddress + "CreateQuotationLine", payload).then(res => {
        var response = res.data.d; 
        if (response.IsSuccess) {       
          quotationProduct.rowOrder = payload.rowOrd;
          quotationProduct.recId = response.RecId;
          commit('setTotals');

          var succesMessage = window.vue.translations.label.label_prod_added_to_quote.replace('[1]', payload.hdrId);
          window.updateOrderMessage(succesMessage);
          resolve();
        } else {
          var errorMessage = window.vue.translations.label.label_err_add_prod_quote;          
          window.updateErrorMessage(errorMessage);
        }
      })

    });    
  },
  showSelectQuotesTool({commit}, show) {
    commit('setShowSelectQuotesTool', show);
  },
  promptUserForQuote({commit}, quoteProduct) {
    commit('setShowSelectQuotesTool', true);
    commit('setQuoteProductInQue', quoteProduct);
    commit('setQueingQuoteProduct', true);
  },
  cancelPromptUserForQuote({ commit }) {
    commit('setShowSelectQuotesTool', false);
    commit('setQuoteProductInQue', null);
    commit('setQueingQuoteProduct', false);
  },
  addQueuedProductToQuoteList({ getters, commit, dispatch }, quoteId) {
    commit('setSelectedQuote', {quoteId: quoteId, isActive: true });

    var quotationProduct = {
      productId: getters.quoteProductInQue.productId, 
      productDescription: getters.quoteProductInQue.productDescription,
      quantity: getters.quoteProductInQue.quantity,
      productPrice: getters.quoteProductInQue.productPrice
    };
    dispatch('cancelPromptUserForQuote');
    dispatch('addProductToQuotation', quotationProduct);
  }
};

const mutations = {
  setQuotations (state, quotations) {
    state.quotations = quotations;
  },
  setLoadingQuotations(state, loading) {
    state.loadingQuotations = loading;
  },
  setSelectedQuote(state, { quoteId, isActive }) {
    state.quotations.forEach(x => {
      x.isActive = false;

      if (x.id === quoteId) {
        x.isActive = isActive;
      }
    });

    var indexSelectedQuote = state.quotations.findIndex(x => x.isActive);

    if (indexSelectedQuote > -1) {
      var quote = {...state.quotations[indexSelectedQuote]};
      state.selectedQuote = quote;
      state.selectedQuoteId = quote.id;
      window.localStorage.setItem('selectedQuoteId', quote.id);
    } else {
      state.selectedQuote = null;
      state.selectedQuoteId = null;
      window.localStorage.removeItem('selectedQuoteId');
    }
  },
  setShowSelectQuotesTool(state, show) {
    state.isShowSelectQuotesTool = show;
    state.isShowSelectQuotesTool = show;
  },
  setQueingQuoteProduct(state, inQue) {
    state.queingQuoteProduct = inQue;
  },
  setQuoteProductInQue(state, quoteProduct) {
    state.quoteProductInQue = quoteProduct;
  },
  setTotals(state, totals) {
    // this.totalExcl = formatPrice(TotalExcl);
    // this.discountTotal = formatPrice(Disc);
    // this.vatTotal = formatPrice(VatTotal);
    // this.totalIncl = formatPrice(GrandTotal);
    // this.quoteDetails.totalsIncl = GrandTotal;
  }
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
