<template>
<div class="select-quote">
  <div class="select-quote-btn">
    
    <a @click="showSelectQuotationModal()">
      <i class="fa fa-file-text-o"></i>
    </a>
  </div>
  
  <transition name="fade">
    <div v-if="isShowSelectQuotesTool" class="full-screen-fader flex-column justify-content-center">
      <div class="utlz-container">
        <div class="utlz-row">
          <div class="utlz-col-lg-12">
            <span class="close-button" @click="closeModal()">
              <i class="fa fa-times-circle" aria-hidden="true"></i>
            </span>
            <div class="modal-content select-quote-modal-content">
              <div class="header">                
                <h2 v-translation="{ code: 'title_select_quote', type: 'title' }"></h2>
                <a :href="'/' + language + '/bespoke/account/quotations.aspx'" class="text-btn" v-translation="{ code: 'button_to_quotes', type: 'button' }"></a>
                <span @click="createQuote()" class="text-btn" v-translation="{ code: 'button_new_quote', type: 'button' }"></span>
              </div>

              <template v-if="queueingQuoteProduct">
                <div class="alert alert-warning" role="alert">
                  <p class="alert-text" v-translation="{ type: 'label', code: 'label_select_quote_first'}"></p>
                </div>
              </template>
              <div class="table-wrapper">
                <table class="table">
                  <thead class="table-header rgba-banner">
                    <th class="is-active" v-translation="{ code: 'label_col_isactive', type: 'label'}">                    
                    </th>
                    <th v-translation="{ code: 'label_col_quote', type: 'label'}">
                    </th>
                    <th v-translation="{ code: 'label_col_date', type: 'label'}" class="col-date">
                    </th>
                    <th v-translation="{ code: 'label_col_customer', type: 'label'}">
                    </th>
                    <th v-translation="{ code: 'label_col_subtotal_exclvat', type: 'label'}">
                    </th>
                  </thead>
                  <tbody>                  
                    <tr v-for="quotation in quotations" :key="quotation.id" @click="setSelection(quotation.id)">
                      <td class="is-active">
                        <input type="checkbox" :checked="quotation.isActive" />
                      </td> 
                      <td>{{ quotation.id }}</td>
                      <td class="col-date">{{ quotation.date }}</td>
                      <td>{{ quotation.name }}</td>
                      <td>{{ formattedPrice(quotation.subtotalExclVat) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>  
        </div>
      </div>
    </div>
    </transition>

</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatPrice } from 'src/utilities/helpers';
export default {
  data() {
    return {
      showModal: false
    }
  },
  computed: {
    ...mapGetters(['language']),
    ...mapGetters('quotations', ['quotations', 'selectedQuote', 'isShowSelectQuotesTool', 'queueingQuoteProduct'])
  },
  methods: {
    ...mapActions('quotations', ['setSelectedQuote', 'promptUserForQuote', 'addQueuedProductToQuoteList', 'cancelPromptUserForQuote', 'showSelectQuotesTool', 'createQuote']),
    formattedPrice (price) {
      return formatPrice(price);
    },
    showSelectQuotationModal() {
      this.showSelectQuotesTool(true);
    },
    closeModal() {
      this.cancelPromptUserForQuote();
    },
    setSelection(selectedQuoteId) {
      var successMessage = window.vue.translations.label.label_switched_quote_list.replace('[1]', selectedQuoteId);
      
      // When selecting a quote, while a quote product is in que awaiting to be added to a quote list
      if (this.queueingQuoteProduct) {
        this.addQueuedProductToQuoteList(selectedQuoteId);
      } else {
        this.setSelectedQuote({ quoteId: selectedQuoteId, isActive: true}).then(_ => { 
          this.showSelectQuotesTool(false);
          window.updateOrderMessage(successMessage);
        });
      }
      
      
    }
  }
}
</script>

<style>

</style>