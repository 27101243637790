<template>
  <transition name="fade">
      <utlz-prompt
        @confirm="deleteQuote($event)"
        @cancel="cancelPrompt()"
      >
      <template v-slot:prompt-content>

        <div class="delete-product-prompt utlz-row">
          <div class="utlz-col-12">
            <h2>
              <span v-translation="{ type: 'title', code: 'title_prompt_delete_quote' }"></span>
            </h2>
          </div>
          <div class="utlz-col-12">
            <span v-translation="{ type: 'label', code: 'label_prompt_delete_quote' }"></span>
          </div>
        </div>
        
      </template>

      </utlz-prompt>
    </transition>
</template>

<script>
import Prompt from './../../bespoke/quotations/Prompt.vue';
export default {
  props: {
    quoteId: { type: String, required: false }
  },
  components: {
    'utlz-prompt': Prompt
  }
  ,
  methods: {
    cancelPrompt() {
      this.$emit('cancelPrompt');
    },
    deleteQuote() {
      this.$emit('deleteQuotationEvent', this.quoteId);
    }
  }
}
</script>

<style>

</style>