import axios from 'axios';
import { Product } from 'src/models/product.model';
import { getProductInformation } from 'src/services/product-service';
// BESPOKE THERM-46: Added getCurrencySign
import { getCurrencySign } from 'src/utilities/helpers';
// END BESPOKE THERM-46


const state = {
  productCode: '',
  productDetails: new Product(),
  groupFields: [],
  productVariants: [],
  relatedProducts: {
    upSelling: [],
    crossSelling: []
  },
  relatedProductCodes: {
    upSelling: [],
    crossSelling: []
  },
  documents: [],
  videos: [],
  bespokeValues: {},
  searchConfig: {
    UserId: null,
    Category: null,
    StringProperties: null,
    NumericProperties: null,
    StringAggregations: null,
    NumericAggregations: null,
    ExtraQueries: []
  }
};

const getters = {
  productCode (state) {
    return state.productCode;
  },
  groupFields (state) {
    return state.groupFields;
  },
  searchConfig (state) {
    return {
      UserId: null,
      Category: state.searchConfig.Category,
      StringProperties: state.activeFilters,
      NumericProperties: null,
      StringAggregations: state.searchConfig.StringAggregations,
      NumericAggregations: null,
      ExtraQueries: state.searchConfig.ExtraQueries,
      ExcludeLabels: state.searchConfig.ExcludeLabels,
      ExcludeCategories: state.searchConfig.ExcludeCategories,
      IncludeLabels: state.searchConfig.IncludeLabels,
      ShouldIncludeLabels: state.searchConfig.ShouldIncludeLabels
    }
  },
  productInformationEndpoint (state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.productInformationEndpoint;
    const client = rootGetters.clientCode;
    const language = rootGetters.language;

    return `${endpoint}/${client}?language=${language}`;
  },
  productVariantEndpoint (state, getters, rootState, rootGetters) {
    const endpoint = rootGetters.productInformationEndpoint;
    const client = rootGetters.clientCode;
    const language = rootGetters.language;

    return `${endpoint}/${client}/GetProductGroupInformation?language=${language}`;
  },
  relatedProducts (state) {
    return state.relatedProducts;
  },
  relatedProductCodes (state) {
    return state.relatedProductCodes;
  },
  documents (state) {
    return state.documents;
  },
  videos (state) {
    return state.videos;
  },
  bespokeValues (state) {
    return state.bespokeValues;
  }
};

const actions = {
  initDetails ({ commit, dispatch }) {
    // BESPOKE THERM-41
    // Get currency sign first
    getCurrencySign().then(res => {
      if (res) {        
        dispatch('getProductInformation', true);
      }
    });
    // END BESPOKE THERM-41
  },
  updateShownProduct ({ commit, getters, dispatch, rootGetters }, productId) {
    commit('setProductCode', productId);
    dispatch('getProductInformation', false);
  },
  getProductInformation ({ commit, getters, dispatch, rootGetters }, initial) {
    let mainProductCodes = [ getters.productCode ];
    axios.post(getters.productInformationEndpoint, mainProductCodes)
      .then(res => {
        if (res.data.length > 0) {
          const product = new Product(res.data[0]);

          if (rootGetters.showStock) {
            dispatch('getProductStock', product);
          } else {
            product.setStock({ stockTotal: 0 });
          }

          if (rootGetters.showPrices) {
            dispatch('getProductPrices', product);
          }

          if (rootGetters.showFavorites) {
            dispatch('getCustomerLists', product);
          }

          dispatch('getProductSeoInformation', product)
          dispatch('getProductImageSeoInformation', product)

          dispatch('genericGetProductInformation', {
            endpoint: rootGetters.productVideosEndpoint,
            productId: product.id,
            setFunction: 'setVideos'
          });

           dispatch('genericGetProductInformation', {
             endpoint: rootGetters.productDocumentsEndpoint,
             productId: product.id,
             setFunction: 'setDocuments'
           });

          dispatch('genericGetProductInformation', {
            endpoint: rootGetters.bespokeProductFieldsEndpoint,
            productId: product.id,
            setFunction: 'setBespokeValues'
          });

          dispatch('genericGetProductInformation', {
            endpoint: rootGetters.relatedProductsEndpoint,
            productId: product.id,
            setFunction: 'setRelatedProductCodes'
          });

          dispatch('getVariantCodes', product)

          commit('setProductDetails', product);

          if (!initial) {
            window.history.replaceState(product, product.shortDescription, product.targetUrl);
          }
        } else {

        }
      });
  },
  genericGetProductInformation ({ commit, dispatch, rootGetters, getters }, callInfo) {
    axios.post(callInfo.endpoint, { productCode: callInfo.productId })
      .then(res => {
        let value = res.data.d;
        commit(callInfo.setFunction, value);

        switch (callInfo.setFunction) {
          case 'setRelatedProductCodes':
            dispatch('getRelatedProducts');
            break;
        }
      });
  },
  getRelatedProducts ({ getters, commit }) {
    if (getters.relatedProductCodes.upSelling.length) {
      // BESPOKE DEPA: Disabled get product stock for related products
      getProductInformation({ prodCodes: getters.relatedProductCodes.upSelling, getSeoInformation: false, getStock: false })
        .then(res => {
          commit('setRelatedProducts', { relatedProducts: res.products, type: 'upSelling' });
        });
    }
    if (getters.relatedProductCodes.crossSelling.length) {
      // BESPOKE DEPA: Disabled get product stock for related products
      getProductInformation({ prodCodes: getters.relatedProductCodes.crossSelling, getSeoInformation: false, getStock: false })
        .then(res => {
          commit('setRelatedProducts', { relatedProducts: res.products, type: 'crossSelling' });
        });
    }
  },
  getVariantCodes ({ commit, dispatch, rootGetters, getters }, product) {
    if (product.variantKey !== '') {
      const client = rootGetters.clientCode;
      const language = rootGetters.language;
      let endpoint = `${rootGetters.elasticProductsEndpoint}/GetByProductVariantKeyWithRestrictions/${encodeURI(product.variantKey)}?language=${language}&client=${client}`;

      axios.post(endpoint, getters.searchConfig)
        .then(res => {
          if (res.data) {
            dispatch('getVariantInformation', { product: product, productCodes: res.data });
          }
        });
    }
  },
  getVariantInformation ({ commit, dispatch, rootGetters, getters }, data) {
    if (data.product.variantKey !== '') {
      let endpoint = getters.productVariantEndpoint + '&variantKey=' + data.product.variantKey;

      axios.post(endpoint, { productCodes: data.productCodes, groupValueFields: getters.groupFields })
        .then(res => {
          if (res.data) {
            const products = res.data.map(product => new Product(product));
            dispatch('getProductVariantDescriptions', products);

            if (rootGetters.showStock) {
              products.forEach((product, index) => {
                axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
                  .then(res => {
                    product.setStock(res.data.d);
                  });
              });
            } else {
              products.forEach(product => product.setStock({ stockTotal: 0 }));
            }
            dispatch('getProductVariantPrices', products);
          }
        });
    }
  },
  getProductVariantPrices ({ commit, getters, dispatch, rootGetters }, products) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    priceRequestWrapper.Products = products.map(prod => {
      let ret = {};
      ret.ProductId = prod.id;
      ret.ProductGroup = prod.discountGroup;

      ret.ProductUnit = '';
      if (prod.units !== undefined && prod.units !== null) {
        ret.ProductUnit = prod.units.length > 0 ? prod.units[0].code : '';
      }
      return ret;
    });

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode;
    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          let product = products[products.findIndex(x => x.id === price.productId)];
          product.setPrices(Object.keys(price.volumes).map(key => price.volumes[key]));
        });
      });
  },
  getProductVariantDescriptions ({ commit, getters, dispatch, rootGetters }, products) {
    commit('setProductVariants', products);
  },
  getProductSeoInformation ({ commit, getters, dispatch, rootGetters }, product) {
    // BESPOKE
    // let endpoint = rootGetters.seoInformationEndpoint + '/GetProductImageInformation';
    // let productCodes = [product.id];

    // axios.post(endpoint, productCodes)
    //   .then(res => {
    //     res.data.forEach(SeoOject => {
    //       product.setSeoInformation(SeoOject);
    //       commit('setProductDetails', product);
    //     });
    //   });
    // END BESPOKE
  },
  getProductPrices ({ commit, getters, dispatch, rootGetters }, product) {
    let priceRequestWrapper = {};
    priceRequestWrapper.CustomerId = rootGetters.userLoggedOn ? rootGetters.customerId : '';
    priceRequestWrapper.Pricelist = rootGetters.customerPriceList;
    let unit = '';
    if (product.units !== undefined && product.units !== null) {
      unit = product.units.length > 0 ? product.units[0].code : '';
    }
    priceRequestWrapper.Products = [ {
      ProductId: product.id,
      ProductGroup: product.discountGroup,
      ProductUnit: unit
    } ];

    let endpoint = rootGetters.productPriceEndpoint + 'prices/' + rootGetters.clientCode

    axios.post(endpoint, priceRequestWrapper)
      .then(res => {
        res.data.forEach(price => {
          const pricesObj = [];
          Object.keys(price.volumes).forEach(key => {
            pricesObj.push({
              price: price.volumes[key].price,
              basePrice: price.volumes[key].basePrice,
              isSalesAction: price.volumes[key].isSalesAction,
              quantity: Math.round(parseInt(key))
            });
          });

          product.setPrices(pricesObj);
          commit('setProductDetails', product);
        });
      });
  },
  getProductStock ({ commit, rootGetters }, product) {
    axios.post(rootGetters.productStockEndpoint, { 'productCode': product.id })
      .then(res => {
        product.setStock(res.data.d);
        commit('setProductDetails', product);
      });
  },
  getCustomerLists ({ commit, getters, rootGetters }, product) {
    const endpoint = `${rootGetters.getProductsInListsEndpoint}?debtorId=${rootGetters.customerId}`;
    axios.post(endpoint, [getters.productCode])
      .then(res => {
        Object.keys(res.data).forEach(prodCode => {
          product.setCustomerLists(res.data[prodCode]);
          commit('setCustomerLists', product);
        });
      });
  },
  getProductImageSeoInformation ({ commit, getters, dispatch, rootGetters }, product) {
    let endpoint = rootGetters.bespokeEndpoints.seoDetailInfo
    let productCode = product.id;

    axios.post(endpoint, { productCode: productCode, language: rootGetters.language })
      .then(res => {
        commit('setSeoImageInfo', res.data.d);
      });
  }
};

const mutations = {
  setProductCode (state, productCode) {
    state.productCode = productCode;
  },
  setProductVariants (state, productVariants) {
    state.productVariants = productVariants;
  },
  setGroupFields (state, groupFields) {
    state.groupFields = groupFields;
  },
  setSearchConfig (state, searchConfig) {
    state.searchConfig = searchConfig;
  },
  setProductDetails (state, product) {
    state.productDetails = product;
  },
  setCustomerLists (state, productWithList) {
    state.productDetails = productWithList;
  },
  setSeoImageInfo (state, imageList) {
    state.imageSeoInfo = imageList;
  },
  setRelatedProducts (state, { relatedProducts, type }) {
    state.relatedProducts[type] = relatedProducts;
  },
  setRelatedProductCodes (state, relatedProductCodes) {
    state.relatedProductCodes = relatedProductCodes;
  },
  setDocuments (state, documents) {
    state.documents = documents;
  },
  setVideos (state, videos) {
    state.videos = videos;
  },
  setBespokeValues (state, bespokeValues) {
    state.bespokeValues = bespokeValues;
  }
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
};
