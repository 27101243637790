<template>
  <div class="cmp-prompt">
    <div class="full-screen-fader flex-column justify-content-center">
      <div class="utlz-container">
        <div class="utlz-row">
          <div class="utlz-col-lg-8 utlz-offset-lg-2">
            <span class="close-button">
              <i class="fa fa-times-circle" aria-hidden="true" @click="cancel()"></i>
            </span>
            <div class="prompt-content">

              <slot name="prompt-content"></slot>
              <div class="action-btns full-width-buttons-mobile">
                <a @click="cancel()" v-translation="{ type: 'button', code: 'button_prompt_cancel'}" class="btn-u cancel"></a>
                <a @click="confirm()" v-translation="{ type: 'button', code: 'button_prompt_confirm'}" class="btn-u btn-u-sea-shop confirm"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: null
  },
  methods: {
    confirm () {
      this.$emit('confirm', this.payload);
    },
    cancel () {
      this.$emit('cancel');
    }
  }
}
</script>

<style>

</style>
