<template>
  <input type="number" v-model="value" class="form-control" @change="updateValue()">
</template>

<script>
export default {
  props: {
    maxValue: { type: Number, required: false, default: 0.00 },
    initialValue: { type: Number, required: false, default: 0.00 }
  },
  data() {
    return {
      value: 0.00
    };
  },
  watch: {    
    initialValue (val) { this.value = val.toFixed(2) }
  },
  methods: {
    updateValue() {
      var parsedValue = this.parseInput(this.value);

      if (this.maxValue > 0) {
        parsedValue = parsedValue > this.maxValue ? this.maxValue : parsedValue;
      }
      if (parsedValue < 0) {
        this.value = this.initialValue.toFixed(2);
      } else {      
        this.value = parsedValue.toFixed(2);
        this.$emit('valueUpdatedEvent', parsedValue);
      }
    },
    parseInput(inputString) {
      var newValue = parseFloat(inputString.replace(",", "."));

      if (isNaN(newValue) || newValue < 0) {
        return -1;
      }

      if (newValue < 0.00) {
        return -1;
      }

      return (Math.round(newValue * 100) / 100);
    }
  },
  created() {
    this.value = this.initialValue.toFixed(2);
  }
}
</script>

<style>

</style>