import { formatPrice } from 'src/utilities/helpers';

export class Product {
  constructor (product) {
    if (product === undefined) {
      this.setEmptyProduct();
    } else {
      this.setProduct(product);
      if (this.images) {
        this.images.unshift(this.imageUrl);
      } else {
        this.images = [this.imageUrl];
      }
    }
  }

  setProduct (product) {
    this.id = product.id;
    this.shortDescription = product.shortDescription;
    this.subTitle = product.subTitle;
    this.longDescription = product.longDescription;
    this.properties = product.properties;
    this.targetUrl = product.targetUrl;
    this.imageUrl = product.imageUrl;
    this.images = product.images;
    this.brand = product.brand;
    this.units = product.units;
    this.saleUnit = product.saleUnit;
    this.discountGroup = product.discountGroup;
    this.prices = [];
    this.units = this.setUnits(product.units);
    this.stock = { stockTotal: null };
    this.variantKey = product.variantKey;
    this.productVariants = [];
    this.customStrings = product.customStrings;
    this.customDecimals = product.customDecimals;
    this.customBooleans = product.customBooleans;
    this.customDateTimes = product.customDateTimes;
    this.includedInCustomerLists = [];
    this.imageAltText = product.id;
    this.imageTitleText = product.id;
    this.productImageBanners = [];
    // BESPOKE DEPG-31
    this.variantCodes = [];
    // END BESPOKE DEPG-31
  }

  setEmptyProduct () {
    this.id = 'NOTLOADED';
    this.shortDescription = '';
    this.subTitle = '';
    this.longDescription = '';
    this.properties = '';
    this.targetUrl = '';
    this.imageUrl = '';
    this.images = [];
    this.brand = '';
    this.variantKey = '';
    this.units = [];
    this.saleUnit = '';
    this.discountGroup = '';
    this.prices = [];
    this.units = null;
    this.stock = { stockTotal: null };
    this.variantKey = '';
    this.productVariants = [];
    this.customStrings = {};
    this.customDecimals = {};
    this.customBooleans = {};
    this.customDateTimes = {};
    this.includedInCustomerLists = [];
    this.imageAltText = '';
    this.imageTitleText = '';
  }

  setStock (stock) {
    this.stock = stock;
  }

  setSeoInformation (seoInfo) {
    this.imageAltText = seoInfo.ImageAltText;
    this.imageTitleText = seoInfo.ImageTitleText;
  }

  setProductImageBanners (banners) {
    const computedBanners = [
      { position: 'top-left', values: [], show: false },
      { position: 'top-right', values: [], show: false },
      { position: 'bottom-right', values: [], show: false },
      { position: 'bottom-left', values: [], show: false }
    ];
    banners.forEach(banner => {
      computedBanners[banner.Location].values.push(banner);
      computedBanners[banner.Location].show = true;
    });
    this.productImageBanners = computedBanners;
  }

  setUnits (units) {
    // BESPOKE DEPG
    // Sometimes products have one unit without a quantity, this needs to be filtered out
    if (units) {
      const computedUnits = units.sort((a, b) => (a.quantity > b.quantity) ? 1 : -1);
      const filteredUnits = computedUnits.filter(unit => unit.quantity > 0);
      return filteredUnits.length > 0 ? filteredUnits : null; 
    } else return null;
    // END BESPOKE DEPG
  }

  setProductVariants (productVariants) {
    this.productVariants = productVariants;
  }

  setCustomerLists (customerList) {
    this.includedInCustomerLists = customerList;
  }

  setPrices (prices) {
    if (prices !== undefined) {
      prices.forEach(priceObj => {
        priceObj.rawPrice = priceObj.price;
        priceObj.rawBasePrice = priceObj.basePrice;
        priceObj.price = formatPrice(priceObj.price);
        priceObj.basePrice = formatPrice(priceObj.basePrice);
        priceObj.quantity = priceObj.quantity === 0 ? 1 : priceObj.quantity;
      });
    }
    this.prices = prices;
  }
}
